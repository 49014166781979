import React from 'react';
import SEO from '../Components/seo';
import {Color, FlexColCenter} from '../Components/Widget';
import RectButton, {BUTTON_SKIN} from '../Components/RectButton';
import {NavActions} from '../Contexts/AppContext';

const NotFoundPage = () => (
  <FlexColCenter>
    <SEO title="404: Not found" />
    <FlexColCenter
      style={{
        width: 280,
        textAlign: 'center',
        alignSelf: 'center',
        margin: '120px 0',
      }}>
      <h1>OOPS!</h1>
      <p
        style={{
          padding: '0 10px',
          marginTop: 16,
          marginBottom: 48,
          color: Color.mainDark_70,
        }}>
        很抱歉，您造訪的頁面已經不存在，
        <br />
        歡迎回到首頁繼續瀏覽！
      </p>
      <RectButton
        skin={BUTTON_SKIN.DEFAULT}
        text="返回首頁"
        onClick={() => {
          NavActions.navigate('/home');
        }}
      />
    </FlexColCenter>
  </FlexColCenter>
);

export default NotFoundPage;
